import React, { Component } from 'react';

export default class Portfolio extends Component{

    displayVideo = (link = null) => {
        const modal = document.getElementsByClassName('modal')[0]
        modal.classList.toggle('modal--hidden')
        const video = document.getElementById('video')
        video.src = `https://www.youtube.com/embed/${link}?autoplay=1`
    }
    
    
    render(){
        return(
            <section id="two">
                <h2>Projects</h2>
                <div className="row">
                    <article className="col-6 col-12-xsmall work-item">
                        <a onClick={event => {this.displayVideo('MfhOjxu14v0')}} className="image fit thumb"><img src="images/brokerbuddyScreen.png" alt="Broker Buddy: fullstack react project" /></a>
                        <h3>Broker Buddy <a href="https://github.com/deanpointblank/BrokerBuddy" className="icon brands fa-github"><span className="label">Github</span></a></h3>
                        <p>Brooker Buddy is a full statck React site meant to help users track multiple stock and save stock information to user-created watchlists</p>
                    </article>
                    <article className="col-6 col-12-xsmall work-item">
                        <a onClick={event => {this.displayVideo('Iz7GD67PrL0')}} className="image fit thumb"><img src="images/simplesurveyScreen.png" alt="Simple Survey: fullstack js project" /></a>
                        <h3>Simple Survey <a href="https://github.com/deanpointblank/SimpleSurvey" className="icon brands fa-github"><span className="label">Github</span></a> </h3>
                        <p>SimpleSurvey is a fullstack web app designed to quickly create, take and see data from surveys.</p>
                    </article>
                    <article className="col-6 col-12-xsmall work-item">
                        <a onClick={event => {this.displayVideo('3YW39uPwxHA')}} className="image fit thumb"><img src="images/spootifyScreen.png" alt="Spootify: fullstack Ruby on rails project" /></a>
                        <h3>Spootify <a href="https://github.com/deanpointblank/Spootify" className="icon brands fa-github"><span className="label">Github</span></a> </h3>
                        <p>This App is called Spootify. It operates as an library to keep track of all of your favorite artist.</p>
                    </article>
                    <article className="col-6 col-12-xsmall work-item">
                        <a onClick={event => {this.displayVideo('_J45bxoRVMU')}} className="image fit thumb"><img src="images/opencoursecliScreen.png" alt="A ruby based CLI application" /></a>
                        <h3>Open Course Degree <a href="https://github.com/deanpointblank/CLI_open_course_degree" className="icon brands fa-github"><span className="label">Github</span></a> </h3>
                        <p>A program that scrapes the web for free courses and formats them into a equivalent of what would be learned in a college degree.</p>
                    </article>
                </div>
		    </section>
        )
    }
}