import React, { Component } from 'react';

export default class Major extends Component{
    render(){
        return(
            <section id="one">
                <header className="major">
                    <h2>Here are my skills:</h2>
                </header>
                <div className="row aln-center">
                    <ul className="labeled-icons col-6">
                        <li><h2><i className="fab fa-react"></i><span className="label">React</span></h2></li>
                        <li><h2><i className="fab fa-js-square"></i><span className="label">JavaScript</span></h2></li>
                        <li><h2><i className="fab fa-react"></i><span className="label">Redux</span></h2></li>
                        <li><h2><i className="fab fa-html5"></i><span className="label">Html</span></h2></li>
                    </ul>
                    <ul className="labeled-icons col-6">
                        <li><h2><i className="fas fa-database"></i><span className="label">SQL</span></h2></li>
                        <li><h2><i className="far fa-gem"></i><span className="label">Ruby</span></h2></li>
                        <li><h2><i className="fas fa-gem"></i><span className="label">Rails</span></h2></li>
                        <li><h2><i className="fab fa-bootstrap"></i><span className="label">Bootstrap</span></h2></li>
                    </ul>
                </div>
			</section>
        )
    }
}