import React from 'react';
import './assets/App.css';

import Header from './containers/header';
import Major from './containers/major';
import Portfolio from './containers/portfolio';
import Contact from './containers/contact';
import Footer from './containers/footer';
import Modal from './components/modal';


function App() {
  return (
    <div className="App">
      <body className="is-preload">

        <Header />
        <div id="main">
          <Major />
          <Portfolio />
          <Contact />

        </div>
        <Footer />

      </body>
      <Modal />
    </div>
  );
}

export default App;
