import React, { Component } from 'react';

export default class Modal extends Component{

    state = {
        vidHeight:0,
        vidWidth:0,
    }


    toggleModal = () => {
        const modal = document.getElementsByClassName('modal')[0]
        modal.classList.toggle('modal--hidden')
        const video = document.getElementById('video')
        video.src = ''
    }

    render(){
        return(
            <div className="modal modal--hidden" onClick={this.toggleModal}>
                <div className="modal_content">
                    <div className="modal_close-bar">
                        <span></span>
                    </div>
                    <div className="iframe-container">
                        <iframe id='video' src="" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount(){
        console.log(document.iframe)
    }
}