import React, { Component } from 'react';

export default class Header extends Component{
    render(){
        return(
            <header id="header">
				<div className="inner">
					<h1><strong>Hi, I'm Dean. <span role="img" alt="hand waving">👋</span></strong><br />
                    I'm a FullStack Software Developer experienced in Ruby on Rails and JavaScript based programming with a background in React and Sinatra.</h1>
				</div>
			</header>
        )
    }
}