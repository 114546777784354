import React, { Component } from 'react';

export default class Footer extends Component{
    render(){
        return(
            <footer id="footer">
				<div className="inner">
					<ul className="icons">
						<li><a href="https://www.linkedin.com/in/deanbetty/" className="icon brands fa-linkedin"><span className="label">LinkedIn</span></a></li>
						<li><a href="https://github.com/deanpointblank" className="icon brands fa-github"><span className="label">Github</span></a></li>
					</ul>
				</div>
			</footer>
        )
    }
}