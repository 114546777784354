import React, { Component } from 'react';

export default class Contact extends Component{

    render(){
        return(
            <section id="three">
                <h2>Get In Touch</h2>
                <p>I'm interested in medium to long-term contracts and full-time opportunities. However, if you have any other request feel free to contact me.</p>
                <div className="row">
                    <div className="col-4 col-12-small">
                        <ul className="labeled-icons">
                            <li>
                                <h3 className="icon solid fa-envelope"><span className="label">Email</span></h3>
                                <a href="mailto:deanlloyd64@gmail.com">deanlloy64@gmail.com</a>
                            </li>
                            <li>
                                <h3><a href="https://www.linkedin.com/in/deanbetty/" className="icon brands fa-linkedin"><span className="label">LinkedIn</span></a></h3>
                                <a href="https://www.linkedin.com/in/deanbetty/">Here's my LinkedIn!</a>
                            </li>
						    <li>
                                <h3><a href="https://github.com/deanpointblank" className="icon brands fa-github"><span className="label">Github</span></a></h3>
                                <a href="https://github.com/deanpointblank">Check out my Github!</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}